export const labels = [
  { key: 'REF INTERNA', value: 'identification' },
  { key: 'STATUS (SITUAÇÃO RÁPIDA)', value: 'status' },
  { key: 'REF PEDIDO/ CLIENTE', value: 'customerRef' },
  { key: 'OUTRA REFERÊNCIA', value: 'anotherRef' },
  { key: 'INVOICE', value: 'invoice' },
  { key: 'DATA DA INVOICE', value: 'invoiceDate' },
  { key: 'PRONTIDÃO (PREV)', value: 'datesGoodsReadinesDate' },
  { key: 'EMBARQUE', value: 'datesDepartureDate' },
  { key: 'CHEGADA', value: 'datesArrivalDate' },
  { key: 'ATRACAÇÃO', value: 'mooringDate' },
  { key: 'INCOTERM', value: 'incoterm' },
  { key: 'VIA DE TRANSPORTE', value: 'wayOfTransport' },
  { key: 'BL/AWB/CRT', value: 'billOfLading' },
  { key: 'VEÍCULO/ NAVIO', value: 'vehicle' },
  { key: 'LOCAL DE EMBARQUE', value: 'placeOfLoading' },
  { key: 'PLANILHA', value: 'stepSpreadsheet' },
  { key: 'SEGURO', value: 'stepInsurance' },
  { key: 'VALOR DO SEGURO', value: 'stepInsuranceValue' },
  { key: 'RESPONSÁVEL P/ SEGURO', value: 'stepInsuranceValueResponsability' },
  { key: 'NUMERÁRIO', value: 'stepCashRequest' },
  { key: 'DOCUMENTOS', value: 'originalsDocumentsDate' },
  { key: 'CONHECIMENTO', value: 'originalsBillOfLadingDate' },
  { key: 'FATURA', value: 'originalsInvoiceDate' },
  { key: 'PACKING LIST', value: 'originalsPackingListDate' },
  { key: 'NÚMERO DI', value: 'diNumber' },
  { key: 'DATA REGISTRO DI', value: 'diRegistryDate' },
  { key: 'DATA DIGITAÇÃO DI', value: 'diTypingDate' },
  { key: 'DATA DESEMBARAÇO', value: 'diResourcefulnessDate' },
  { key: 'CANAL DI', value: 'diChannel' },
  { key: 'NÚMERO DUIMP', value: 'duimpIdentification' },
  { key: 'DATA REGISTRO DUIMP', value: 'duimpRegistryDate' },
  { key: 'DATA DE COLETA', value: 'datesCargoCollectDate'},
  { key: 'DATA DE COLETA PREVISTA', value: 'datesEstimatedCargoCollectDate'},
  { key: 'DATA DIGITAÇÃO DUIMP', value: 'duimpTypingDate' },
  // {"key": "", "value": "duimpResourcefulnessDate"},
  // {"key": "", "value": "duimpChannel"},
  { key: 'NÚMERO DO RAS', value: 'siscoservNRas' },
  { key: 'DATA DE REGISTRO DO RAS', value: 'siscoservRASDate' },
  { key: 'NÚMERO DO RP', value: 'siscoservNRP' }, ,
  { key: 'DATA DE REGISTRO DO RP', value: 'billingNFsDate' },
  { key: 'FATURAMENTO DO CLIENTE', value: 'billingBillCustomerDate' },
  { key: 'EMAIL FATURAMENTO', value: 'billingEmailDate' },
  { key: 'ENVIO FATURAMENTO', value: 'billingSendDate' },
  { key: 'ENC DESPACHANTE', value: 'billingCustomBrokerFinishDate' },
  { key: 'EMPRESA', value: 'billingSendCompanyDescription' },
  { key: 'TRACKING', value: 'billingSendTrackingDescription' },
  { key: 'CONFERÊNCIA DE DADOS', value: 'billingDataConferenceDate' },
  { key: 'PRONTIDÃO (PREV)', value: 'datesEstimatedGoodsReadinesDate' },
  { key: 'ETD', value: 'datesETD' },
  { key: 'ETA', value: 'datesETA' },
  { key: 'ENTREGA CLIENTE (PREV)    ', value: 'datesEstimatedCustomerDeliverDate' },
  { key: 'PRESENÇA', value: 'datesGoodsPresenceDate' },
  { key: 'VISTORIA DA DI', value: 'datesInspectionOfTheDIDate' },
  { key: 'CARREGAMENTO', value: 'datesGoodsLoadingDate' },
  { key: 'ENTREGA NO CLIENTE', value: 'datesCustomerDeliverDate' },
  { key: 'VISTORIA MAPA', value: 'datesInspectionMAPADate' },
  { key: 'LIBERAÇÃO MAPA', value: 'datesReleaseMAPADate' },
  { key: 'VÍNCULO NO RECINTO', value: 'datesLinkInTheEnclosureDate' },
  { key: 'LIBERAÇÃO AWB/BL', value: 'datesBillOfLadingReleaseDate' },
  { key: 'FAT RECINTO', value: 'datesEnclosureBillingDate' },
  { key: 'COMEX', value: 'datesComexDate' },
];
